

body, button, input, select, textarea {
    color: #000;
}

::-moz-selection {
    background: #fe57a1;
    color: #fff;
}

::selection {
    background: #fe57a1;
    color: #fff;
}

a {
    color: #222;
    &:hover {
        color: #FFBF00;
    }
    &:visited{
        color: #222;
    }
}

hr {
    border-top: 1px solid #ccc;
}

ins {
    background: #ff9;
    color: #000;
}

mark {
    background: #ff0;
    color: #000;
}

.produit_etiquette:after {
    border-color: #8C8C8C transparent transparent;
}

.produit_etiquette, .produit_etiquette2 {
    background: #8C8C8C;
    h3, .h3{
        color:#fff;
    }
}

.produit_etiquette2:after {
    border-color: rgba(0, 0, 0, 0) #8C8C8C rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

/* Titres */

.title_section, h2, .apercu-article h2.titre-article, .single-article .titre-article h1,
.title_section, .h2, .apercu-article .h2.titre-article, .single-article .titre-article .h1 {
    background-color: #ddd;
    border-bottom-color: #555555;
    color: #000000;
}

/* Boutons */

.wrapper .addmsglo, .wrapper .button:not(.zoom), .wrapper .bouton, .wrapper input[type="submit"], .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a, .accueil_diaporama_template .slide .slide-text .slider-see-more, .wrapper button, #block-flottant-search button, .template-panier .bouton, #fiche-produit .radio_unique  {
    border-color:#8c8c8c;
    background-color: #8C8C8C;
    color: #fff;
    &:hover, &:focus {
        background-color: #8C8C8C;
        color: #fff;
    }
}

/*-----------------------------
CORPS
-------------------------------*/

body {
    background-color: #eee;
}

#main-conteneur {
    box-shadow: 0 3px 2px #fff;
    .wrapper-content::after, .template_contact_5 .inmap {
        background: #fff;
    }
}

.menu-principal.navigation_p, .header_p,
.wrapper-content::before, #nav-principal::before,
.headerBackground{
    background: #DDDDDD;
}

.wrapper-content::after, #nav-principal > ul {
    border-left-color: #263233;
}

/*-----------------------------
BLOG
-------------------------------*/

/* Pagination */

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #555555;
    border-color: #555555;
}

.pagination > li > a, .pagination > li > span {
    color: #fff;
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

/*-----------------------------
NAVIGATION
-------------------------------*/

#nav-principal, #nav-secondary, #nav-principal::before {
    border-bottom-color: #555555;
    border-left-color: #263233;
}

.navigation_button_p {
    background: #8C8C8C;
    color: #fff;
    > .navigation_link_p {
        color: #fff;
    }
    &.actif, &.sousmenu_ouvert, &:hover, &.menu_deploye {
        background:#fff;
        color: #8C8C8C;
        > .navigation_link_p {
            color: #8C8C8C;
        }
    }
}

.sous_navigation_button_p {
    background-color: #fff;
    color: #8C8C8C;
    .sous_navigation_link_p {
        color: #8C8C8C;
    }
    &.actif, &.sousmenu_ouvert, &:hover {
        background-color: #8C8C8C;
        color: #fff;
        > .sous_navigation_link_p {
            color: #fff;
        }
    }
}

.template-panier{
    background: #8c8c8c;
    color: #fff;
    #header-panier{
        color:#fff;
    }
    .count-live{
        background: #fff;
        color:#000;
    }
}

/*
 * Fils d'ariane
 */

nav.arianne {
    border-bottom-color: #555555;
    border-left-color: #263233;
}

/*-----------------------------
GALERIE
-------------------------------*/

.galerie{
    background-color: #8C8C8C;
    .commentP, .commentP .mask {
        background: #8C8C8C;
        p {
            color: #fff;
        }
    }
}

.produits .zoom, .galerie .mask, .galerie {
    background-color: #dddddd;
}

/*-----------------------------
BOUTIQUE
-------------------------------*/

.produit_galerie_border_p {
    border-color: #8C8C8C;
}

.produits {
    background-color: #fff;
    .nomprod {
        background-color: #8C8C8C;
        border-bottom-color: #fff;
        a, &:visited, a:hover{
            color: #fff;
        }
    }
    .productImageWrap {
        background-color: #000;
    }
    .oldprix {
        color: red;
    }
}
.remise, .sub-remise, #fiche-produit .remise-produit {
    color:#e5c100;
    background-color: #e5c100;
}
.text-remise{
    color: #fff;
}

/*-----------------------------
LIVRE D'OR
-------------------------------*/

.message_lo {
    border-right-color: #CCCCCC;
    border-left-color: #CCCCCC;
    border-bottom-color: #CCCCCC;
    .note {
        background-color: #A3A3A3;
        border-left-color: #FFFFFF;
        color: #FFFFFF;
    }
}

.template_livreor{
    &.template_livreor_2 .message_lo{
        .contenuMsgLO, &:hover .contenuMsgLO{
            border-color:#A3A3A3;
            .blcFleche{
                &::before{
                    border-color:#A3A3A3;
                }
            }
        }
        .IDLO, &:hover .IDLO{
            border-left-color:#A3A3A3;
        }
    }
    &.template_livreor_3 .message_lo{
        .message{
            &::before, &::after{
                color:#A3A3A3;
            }
        }
    }
}

/*-----------------------------
PAGE DE CONTACT
-------------------------------*/

.form {
    border-right-color: #CCCCCC;
    border-left-color: #CCCCCC;
    border-bottom-color: #CCCCCC;
    box-shadow: 0 1px 2px #DDDDDD;
}

/*-----------------------------
DIAPORAMAS
-------------------------------*/

.bx-wrapper,
.accueil_diaporama_template .bx-wrapper,
.accueil_boutique_template_1 .bx-wrapper {
    @at-root #fiche-produit .previews .next, #fiche-produit .previews .previous, & a.bx-next, & a.bx-prev {
        border-color:#ddd;
        background:#fff;
        &::after{
            color:#ddd;
        }
        &:hover{
            background:#ddd;
            &::after{
                color:#000;
            }
        }
    }
    @at-root #fiche-produit .bx-pager-item, & .bx-pager-item{
        .bx-pager-link {
            border-color:#000;
            background:transparent;
            &:hover, &:focus, &.active{
                background:#000;
            }
        }
    }
}
#main-conteneur .accueil_diaporama_template{
    background-color: #ddd;
    border-bottom-color: #555555;
}

/*-----------------------------
PIED DE PAGE
-------------------------------*/

footer {
    color:#fff;
    background-color: #B8B8B8;
    border-top-color: #FFBF00;
    a,a:hover,a:visited{
        color:#fff;
    }
}

/*-----------------------------
FLUX PANIER
-------------------------------*/

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #8C8C8C;
    border-color: #8C8C8C;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #8C8C8C;
    color: #8C8C8C;

    a, span {
        color: #8C8C8C;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}